import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'

const isDev = process.env.NODE_ENV !== 'production'

let config = {
  baseURL: process.env.VUE_APP_apiBaseUrl || '',
  timeout: 60 * 1000,
  withCredentials: false
}

function createIntance() {
  console.log('>>>>>>> create axios instance')
  let instance = axios.create(config)

  instance.interceptors.request.use(
    config => {
      if (localStorage.token) {
        config.headers['Bm-Admin-Token'] = localStorage.token
      }
      config.headers['Tenant-Id'] = localStorage.tenantId
      config.headers['Bm-Area-Id'] = config.headers['Bm-Area-Id'] || localStorage.areaId

      if (isDev) {
        config.url = config.url.replace('/boom-px-api-service/', '/')
      }

      if (config.params) {
        delete config.params.total
        delete config.params.isFinish
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    response => {
      if (response.headers['new-token']) {
        localStorage.token = response.headers['new-token']
      }
      if (response.data.err === 0) {
        logAxiosSuccess(response)
        if (response.data.list) {
          let page = response.data.page
          if (page) {
            page.isFinish = page.page * page.size >= page.total
          }
          return { page, list: response.data.list, other: response.data.other }
        }
        return response.data.data || response.data
      } else {
        logAxiosError(response)
        return Promise.reject(response.data)
      }
    },
    error => {
      return Promise.reject(error)
    }
  )
  return instance
}

export function useAxiosInstance(app) {
  if (!isDev && window?.$isMicroApp) {
    app.config.globalProperties.$http = window.$globalUtil.axios
  } else {
    app.config.globalProperties.$http = createIntance()
  }
}
