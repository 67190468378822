<template lang="pug">
.isStandaloneApp(:class="{isMicroApp}")
  router-view

</template>

<script setup>
import { provide, onMounted } from 'vue'
import globalProvider from '@/provider/global'
import { useRouter, useRoute } from 'vue-router'

const { loading } = globalProvider
const isMicroApp = !!window.__POWERED_BY_QIANKUN__

onMounted(() => {
  if (isMicroApp) {
  } else {
    let queryStringList = []
    if (location.href.split('?')[1]) {
      queryStringList = location.href
        .split('?')[1]
        .split('&')
        .map(i => ({ key: i.split('=')[0], value: i.split('=')[1] }))
    }
    const token = queryStringList.find(i => i.key === 'token')?.value
    const tenantId = queryStringList.find(i => i.key === 'tenantId')?.value
    if (token) {
      localStorage.token = token
      localStorage.tenantId = tenantId || 1
    } else if (!localStorage.token) {
      useRouter().push('/account/login')
    }
  }
})

provide('global', {
  ...globalProvider,
  fetch: isMicroApp ? window.$globalUtil.axios : app.config.globalProperties.$http,
  router: useRouter(),
  route: useRoute()
})
</script>

<style lang="less">
@import './style/reset.less';
@import './style/var.less';

.isStandaloneApp {
  height: 100vh;
  --maxPageHeight: 100vh;
}
.isMicroApp {
  height: calc(100vh - var(--heraderBar-height));
  --maxPageHeight: calc(100vh - var(--heraderBar-height));
}
</style>
