import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/page/index.vue')
  },
  {
    path: '/editor/workspace',
    component: () => import('@/page/editor/workspace.vue')
  },
  {
    path: '/asset',
    component: () => import('@/page/asset/index.vue')
  },
  {
    path: '/retouch',
    component: () => import('@/page/retouch/index.vue')
  },
  {
    path: '/account/login',
    component: () => import('@/page/account/login.vue')
  },
  {
    path: '/debug',
    component: () => import('@/page/debug/index.vue')
  }
]

const isMicroApp = !!window.__POWERED_BY_QIANKUN__
const isDev = process.env.NODE_ENV !== 'production'

let base = '/child/px/'
if (isMicroApp) {
  base = '/px/'
}
if (!isMicroApp && isDev) {
  base = '/'
}

export function useRouter(app) {
  if (app) {
    const routeInstance = createRouter({
      history: createWebHistory(base),
      routes
    })
    app.use(routeInstance)
  }
}
