if (!['prod'].includes(process.env.VUE_APP_env)) {
  if (window.__POWERED_BY_QIANKUN__) {
    __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
  }
}

import { createApp } from 'vue'
import App from './App.vue'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import { initMicroApp, initGlobalState } from '@/plugin/qiankun'

export let app = null

function render(props = {}) {
  app = createApp(App)
  const { container } = props
  const { useRouter } = require('@/plugin/router')
  const { useDirective } = require('@/plugin/directive')
  const { useAxiosInstance } = require('@/plugin/axios')

  useRouter(app)
  useDirective(app)
  useAxiosInstance(app)
  app.use(Antd)

  app.mount(container ? container.querySelector('#microApp-px') : '#microApp-px')
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
  window.$isMicroApp = false
}

export async function bootstrap(props) {
  await initMicroApp(app, props)
}
export async function mount(props) {
  render(props)
  initGlobalState(app, props)
  window.$isMicroApp = true
}
export async function unmount() {
  if (app) {
    app.unmount()
    app._container.innerHTML = ''
    app = null
  }
}
