let log = console.log

export const initMicroApp = async (app, props) => {
  log('>>>>>>> onBootstrap initMicroApp props', props)
  if (props?.util?.log) {
    log = props.util.log
  }
  window.$globalUtil = props.util
  window.$mainRouter = props.util.mainRouter
  window.$globalMethod = props.method
  window.$globalConfig = props.config
  window.$onGlobalStateChange = props.onGlobalStateChange
  window.$setGlobalState = props.setGlobalState
}
export const initGlobalState = (app, props) => {
  log('>>>>>>> initGlobalState props', props)
  props.onGlobalStateChange(onGlobalStateChange)
}

export const onGlobalStateChange = (state, prev) => {
  log('>>>>>> 子应用监听到主应用改变', state)
}
